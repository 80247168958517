<template>
    <div class="purchase">
        <h3 class="title">
            {{ $t("purchase.title") }}
        </h3>
        <div class="body">
            <FormView @submit="onSubmit">
                <div class="row">
                    <div class="col-12">
                        <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                            {{ msg }}
                        </div>
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-md-3 frm-l">
                        <label class="label">{{ $t('purchase.teacher') }}</label>
                    </div>
                    <div class="col-md-9 text frm-r">
                        {{ detail.teacher.name }}
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-md-3 frm-l">
                        <label class="label">{{ $t('purchase.lesson') }}</label>
                    </div>
                    <div class="col-md-9 text frm-r">
                        {{ detail.title }}
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-md-3 frm-l">
                        <label class="label">{{ $t('purchase.price') }}</label>
                    </div>
                    <div class="col-md-9 text frm-r">
                        {{ detail.price_detail && formatCurrency(detail.price_detail.currency, detail.price_detail.price) }} {{ $t("lesson.included_tax") }}
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-md-3 frm-l">
                        <label class="label">{{ $t('purchase.optional_item') }}</label>
                    </div>
                    <div class="col-md-9 text frm-r">
                        <template v-if="detail.price_detail">
                            <div v-for="(item, index) in detail.price_detail.items" :key="index">
                                <b>{{ item.name }}</b> <label>({{ formatCurrency(detail.price_detail.currency, item.price)
                                }}) {{ $t("lesson.included_tax") }}</label>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-md-3 frm-l">
                        <label class="label">{{ $t('purchase.total') }}</label>
                    </div>
                    <div class="col-md-9 text frm-r">
                        {{ detail.price_detail && formatCurrency(detail.price_detail.currency,
                            detail.price_detail.sub_total) }} {{ $t("lesson.included_tax") }}
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-lg-12">
                        <InlineInput :label="$t('purchase.age_or_grade')" :placeholder="$t('purchase.age_or_grade')"
                            name="name" rules="max:50" v-model="purchase.age_or_grade" />
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-lg-12">
                        <InlineTextArea :label="$t('purchase.content')" :placeholder="$t('purchase.content_placeholder')" required
                            name="content" :rows="15" v-model="purchase.content" />
                    </div>
                </div>
                <div class="row group-frm">
                    <div class="col-12 claim-policy">
                        <b class="text-pre-line ml-1 mt-3">【ご注意事項】</b>
                        <div class="text-pre-line ml-1">＜予約のキャンセル＞</div>
                        <ul class="ul">
                            <li> 予約相談開始前24時間未満の場合、生徒はキャンセルをすることができません。 </li>
                            <li> 購入後5日が経過している場合、講師・生徒ともにキャンセルをすることができません。</li>
                            <li> 支払方法はクレジットカード・デビットカード、Link（ワンクリック決済）になります。</li>
                            <li> 購入後5日後以降に相談が実施される場合は、購入から5日後にカードへの請求が発生いたします。</li>
                            <li> 購入後5日間以内に相談が実施される場合は、相談を実施し受講完了報告後にカードへの請求が発生いたします。</li>
                        </ul>
                    </div>
                </div>
                <div class="group-frm text-center">
                    <router-link to="/terms" target="_blank">利用規約</router-link> |
                    <router-link to="/privacy-policy" target="_blank">個人情報の取り扱いについて</router-link>
                </div>
                <div class="row">
                    <div class="col-lg-12 text-center d-flex">
                        <router-link :to="`/lesson-detail/${lesson_id}`" class="btn btn-cancel">
                            キャンセル
                        </router-link>
                        <button v-if="isPageLoaded" class="btn btn-primary" type="submit" :disabled="submitting">
                            {{ $t('purchase.submit') }} {{ submitting ? '...' : '' }}
                        </button>
                    </div>
                </div>
            </FormView>
            <button class="hide-mms" ref="btnPayment" :mms-product-id="this.$route.query.mmspid"
                :mms-item-id="this.$route.params.id" :mms-total-price="this.$route.query.mmstp">payment</button>

        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    name: "SubmitForm",
    data() {
        return {
            purchase: {
                age_or_grade: "",
                content: "",
            },
            detail: {
                teacher: {},
                price_detail: {}
            },
            msgType: "danger",
            msg: "",
            submitting: false,
            id: 0,
            isPageLoaded: false,
        };
    },
    mounted() {
        this.$isLoading(true)
        document.addEventListener('readystatechange', event => {
            if (event.target.readyState == "complete") {
                setTimeout(() => {
                    this.isPageLoaded = true;
                    this.$isLoading(false)
                }, 1500);
            }
        });
    },
    async created() {
        this.id = this.$route.params.id;
        await this.loadSchedulePurchase({
            id: this.id,
        });
        window.MMSCallback.setPaymentListener(async ({ data }) => {
            let { id, stripe_order, items } = data;
            const qty = items.reduce((a, { qty }) => a + qty, 0);
            let purchase = {
                sale_id: id,
                order: {
                    order_id: stripe_order.id,
                    amount: stripe_order.amount,
                    currency: stripe_order.currency,
                },
                price_detail: this.detail.price_detail,
                qty: qty
            };
            await this.comfirmTransaction(purchase);
        });
    },
    methods: {
        ...mapActions({
            verifyItems: "lessonDetail/verifyItems",
            confirmPurchase: "lessonDetail/confirmPurchase"
        }),
        async loadSchedulePurchase(params) {
            await this.verifyItems({
                params: params,
                cb: (response) => {
                    let { data, meta } = response;
                    if (meta && meta.code == 200) {
                        this.lesson_id = data.lesson_id;
                        this.detail = data;
                        if (data.teacher.member_id == this.getMemberId()) {
                            this.$router.back();
                        }
                    } else {
                        this.$router.back();
                    }
                }
            });
        },
        async onSubmit() {
            this.$refs.btnPayment.click();
        },
        async comfirmTransaction(data) {

            this.purchase = {
                ...this.purchase,
                ...data,
            }
            await this.confirmPurchase({
                data: this.purchase,
                cb: (response) => {
                    let { data, meta } = response;
                    if (meta && meta.code == 200) {
                        this.msg = "ご注文ありがとうございました!";
                        this.msgType = "success";
                        this.$router.push("/my-page");
                    } else {
                        if (data.errors) {
                            let field = Object.keys(data.errors)[0];
                            this.msg = this.$t(data.errors[field][0], {
                                _field_: "お問い合わせ詳細"
                            });
                        }
                        else {
                            if (data && data.message) {
                                this.msg = data.message;
                            }
                            else {
                                this.msg = this.$t("purchase.failed");
                            }
                        }
                        this.msgType = "danger";
                    }
                }
            })
        },
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.id = this.$route.params.id;
                this.loadSchedulePurchase({
                    id: this.id,
                });
            },
        },
    }
}
</script>